<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>添加会员</span>
            </div>

            <!-- 添加会员-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button
                            type="text"
                            @click="jumpToMember"
                            class="font1"
                            :disabled="!hasCreateMemberPrivilege"
                        >
                            添加会员
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <el-tooltip
                            class="font2"
                            style="text-decoration: underline"
                            :content="roleNamesForCreateMember"
                            placement="top"
                        >
                            <span>此类角色</span>
                        </el-tooltip>
                        可添加会员
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button type="text" class="font2" @click="jumpToMember" :disabled="!hasCreateMemberPrivilege"
                            >①添加会员信息</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击新建-选择机构名称-点击添加-输入姓名和手机号(其他内容为选填)-如需继续添加点击添加按钮-完成点击保存
                        </span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const memberMenuPrivilege = 'menu.member.show';
const memberPrivilegeFlag = 'menu.member.member';
const createMemberPrivilegeFlag = `${memberPrivilegeFlag}.create`;

export default {
    name: 'AddMemberGuide',
    data() {
        return {
            rolesForCreateMember: [],
        };
    },
    created() {
        //查询“能够创建怀远”的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createMemberPrivilegeFlag).then((rst) => {
            this.rolesForCreateMember = rst;
        });
    },
    computed: {
        roleNamesForCreateMember() {
            return this.rolesForCreateMember.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToMember() {
            if (!this.hasCreateMemberPrivilege()) {
                return;
            }
            Util.nameJump(this, memberPrivilegeFlag);
        },
        hasCreateMemberPrivilege() {
            return this.hasPrivilege(createMemberPrivilegeFlag) && this.hasPrivilege(memberMenuPrivilege);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
